import React from "react"
import { GlobalProvider } from "../context/GlobalContext"
import LandingPage from "../components/LandingPage"
import Footer from "../components/Footer"
import Apropos from "../components/Apropos"
import Tarifs from "../components/Tarifs"
import Menu from "../components/Menu"
import Contact from "../components/Contact"
import SEO from "../components/seo"
import Principes from "../components/Principes"
import BienFait from "../components/BienFait"
import PourQui from "../components/PourQui"

const Home = () => {
  return (
    <GlobalProvider>
      <SEO title="Home" />
      <Menu />
      <LandingPage />
      <Apropos />
      <Principes />
      <BienFait />
      <PourQui />
      <Tarifs />
      <Contact />
      <Footer />
    </GlobalProvider>
  )
}

export default Home
