import React, { useContext, useState } from "react"

import styled from "styled-components"
import { GlobalContext } from "../context/GlobalContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FontAwesome from "./FontAwesome"

FontAwesome()

const Menu = () => {
  const data = useContext(GlobalContext)
  const [sociaux] = useState(data[0].allDatoCmsSociaux.nodes)

  return (
    <MenuBarWrapper>
      <SocialWrapper>
        <a href={sociaux[0].lien}>
          <FontAwesomeIcon icon={["fab", "facebook"]} />
        </a>

        <a href={sociaux[1].lien}>
          <FontAwesomeIcon icon={["fab", "instagram"]} />
        </a>
      </SocialWrapper>
      <MenuWrapper>
        <ul>
          <li>
            <a href="#Tarifs">
              <span>Tarifs</span>
            </a>
          </li>
          <li>
            <a href="#Contact">
              <span>Contact</span>
            </a>
          </li>
        </ul>
      </MenuWrapper>
    </MenuBarWrapper>
  )
}

const MenuBarWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  margin-top: 20px;
`
const MenuWrapper = styled.div`
  width: 200px;
  ul {
    display: flex;
    list-style: none;
  }

  ul li {
    padding: 0.5rem;
    font-size: 1.5rem;
    margin: 0 0.5rem;
    color: #46aeb7;
  }
  a {
    text-decoration: none;
    color: #46aeb7;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    span {
      font-size: 1.5rem;
      font-weight: semibold;
    }
  }

  a:hover {
    color: #024873;
  }
`
const SocialWrapper = styled.div`
  display: flex;
  width: 100px;
  justify-content: flex-start;
  margin: 1rem;

  a {
    width: 4rem;
    color: #46aeb7;
    font-size: 2rem;
    padding: 0 0.5rem;
    text-decoration: none;
    transition: 0.7s ease-in-out;
    cursor: pointer;
  }

  a:hover {
    color: #024873;
  }
`

export default Menu
