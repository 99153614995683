import React, { useContext, useState } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { GlobalContext } from "../context/GlobalContext"

const Principes = () => {
  const data = useContext(GlobalContext)
  const [dataPrincipes] = useState(data[0].allDatoCmsLesPrincipe.edges[0].node)
  return (
    <>
      <h2
        style={{
          textAlign: "center",
          color: "#333",
          marginTop: "2rem",
          fontSize: "2rem",
        }}
      >
        {dataPrincipes.title}
      </h2>
      <AboutWrapper>
        <h6>
          <ReactMarkdown source={dataPrincipes.description} />
        </h6>
      </AboutWrapper>
    </>
  )
}

const AboutWrapper = styled.div`
  margin: 3rem 0;
  color: #333;
  width: 90%;
  justify-content: center;

  h6 {
    justify-content: center;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: justify;
  }
`

export default Principes
