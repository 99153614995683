import React, { useContext, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FontAwesome from "./FontAwesome"
import { GlobalContext } from "../context/GlobalContext"
FontAwesome()

const Footer = () => {
  const data = useContext(GlobalContext)
  const [sociaux] = useState(data[0].allDatoCmsSociaux.nodes)
  const [horaires] = useState(data[0].allDatoCmsHoraire.edges)
  return (
    <>
      <FooterWrapper>
        <div>
          <h2>Adresse</h2>
          <p>159 Chemin de la Bayette, 83220 Le Pradet</p>
          <p>Tel: 06 11 41 63 03</p>
        </div>
        <div>
          <h2>Nous suivre</h2>
          <div className="socialWrapper">
            <a href={sociaux[0].lien}>
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
            <a href={sociaux[1].lien}>
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
          </div>
        </div>
        <div>
          <h2>Horaires</h2>
          {horaires.map(({ node: horaire }) => {
            return (
              <p key={horaire.id}>
                {horaire.jour}: {horaire.time}
              </p>
            )
          })}
        </div>
      </FooterWrapper>
      <p
        style={{
          textAlign: "center",
          alignSelf: "end",
          background: "#fafafa",
          color: "#333",
        }}
      >
        © 2020, Creation{" "}
        <a
          href="http://www.mvdev.fr"
          style={{ textDecoration: "none", color: "#46aeb7" }}
        >
          MVDev
        </a>
      </p>
    </>
  )
}
//CSS
const FooterWrapper = styled.div`
  width: 100%;
  min-height: 300px;
  background: #fafafa;
  color: #333;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  text-align: center;

  h2 {
    color: #46aeb7;
    margin: 2rem 0;
  }
  .socialWrapper {
    width: 1rem;
    display: flex;
    width: 90%;
    padding: 1rem;
    justify-content: center;
  }

  .socialWrapper a {
    width: 4rem;
    color: #46aeb7;
    font-size: 2rem;
    padding: 0 1rem;
    text-decoration: none;
    transition: 0.7s ease-in-out;
    cursor: pointer;
  }

  .socialWrapper a:hover {
    color: #c9ac8c;
  }
`
export default Footer
