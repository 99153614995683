import React from "react"
import Logo from "../images/StudioPilates.png"
import Background from "../images/backmin.jpg"
import styled from "styled-components"

const LandingPage = () => {
  return (
    <LandingWrapperImg>
      <img src={Logo} alt="Logo de Choréart Pilate" />
    </LandingWrapperImg>
  )
}

const LandingWrapperImg = styled.div`
  text-align: center;
  background: url(${Background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 40rem;

  img {
    width: 18rem;
    margin-top: 3rem;
  }
`

export default LandingPage
