import React, { createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsForfait(sort: { fields: meta___createdAt }) {
        edges {
          node {
            id
            typeForfait
            tarif
            description
          }
        }
      }
      allDatoCmsProfesseur {
        edges {
          node {
            id
            nom
            prenom
            photo {
              url
            }
            description
          }
        }
      }
      allDatoCmsSociaux {
        nodes {
          id
          title
          lien
        }
      }
      allDatoCmsAbout {
        edges {
          node {
            id
            description
            picture {
              url
            }
          }
        }
      }
      allDatoCmsHoraire(sort: { fields: meta___createdAt }) {
        edges {
          node {
            jour
            id
            time
          }
        }
      }
      allDatoCmsLesPrincipe {
        edges {
          node {
            title
            description
          }
        }
      }
      allDatoCmsBienfait {
        edges {
          node {
            title
            description
          }
        }
      }
      allDatoCmsPourQui {
        edges {
          node {
            title
            description
          }
        }
      }
    }
  `)
  return (
    <GlobalContext.Provider value={[data]}>{children}</GlobalContext.Provider>
  )
}
