import { library } from "@fortawesome/fontawesome-svg-core"
import {
  fab,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

export default function FontAwesome() {
  library.add(fab, faFacebook, faInstagram)
}
