import React, { useState } from "react"
import styled from "styled-components"

const Contact = () => {
  const [protect, setProtect] = useState(0)
  const [submitMail, setSubmitMail] = useState(null)
  const onSubmit = () => {
    if (protect === 0) {
      setSubmitMail(
        "https://www.flexyform.com/f/17cf016338bc76213876bab9987cb843067094f6"
      )
    }
  }

  return (
    <ContactWrapper id="Contact">
      <h1>Nous Contacter</h1>
      <FormWrapper>
        <form method="post" action={submitMail}>
          <p>Nom</p>
          <input type="text" name="fullname" required />
          <input
            style={{ display: "none" }}
            name="protect"
            onChange={e => setProtect(e.target.value)}
            value={protect}
          />
          <p>Email</p>
          <input type="email" name="email" />
          <p>N° Téléphone</p>
          <input type="number" name="phone" />
          <p>Message</p>
          <textarea name="message" rows="5"></textarea>
          <button
            type="submit"
            className="button special"
            onClick={e => onSubmit(e)}
          >
            Envoyer
          </button>
        </form>
      </FormWrapper>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  width: 100%;
  margin-top: 3rem;

  h1 {
    text-align: center;
    color: #333;
    margin: 4rem 0;
  }
`

const FormWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 2rem;
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  padding: 2px 16px;

  p {
    margin-top: 2rem;
    color: #333;
  }
  input {
    width: 100%;
    height: 3rem;
    border: 0.1rem solid #46aeb7;
    box-sizing: border-box;
    -moz-appearance: textfield;
    outline: none;
  }

  textarea {
    min-height: 7rem;
    max-height: 7rem;
    min-width: 100%;
    max-width: 100%;
    border: 0.1rem solid #46aeb7;
    outline: none;
  }

  button {
    width: 5rem;
    height: 3rem;
    margin: 2rem;
    border-radius: 0.5rem;
    border: 0.1rem solid #46aeb7;
    color: #46aeb7;
    background: #fff;
    transition: 0.5s ease-in-out;
    outline: none;
  }
  button:hover {
    border: 0.1rem solid #fff;
    color: #fff;
    background: #46aeb7;
    outline: none;
  }
`

export default Contact
