import React, { useContext, useState } from "react"
import styled from "styled-components"
import { GlobalContext } from "../context/GlobalContext"

const Tarifs = () => {
  const data = useContext(GlobalContext)
  const [forfaits] = useState(data[0].allDatoCmsForfait.edges)
  return (
    <div id="Tarifs">
      <h1
        style={{
          textAlign: "center",
          fontSize: "3rem",
          color: "#333",
          marginTop: "4rem",
        }}
      >
        Nos Forfaits
      </h1>
      <PriceWrapper>
        {forfaits.map(({ node: forfait }) => {
          return (
            <Card key={forfait.id}>
              <h1>{forfait.typeForfait}</h1>
              <h1>{forfait.tarif}</h1>

              <h2>{forfait.description}</h2>
            </Card>
          )
        })}
      </PriceWrapper>
    </div>
  )
}

const PriceWrapper = styled.div`
  margin: 3rem 0;
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const Card = styled.div`
  width: 20rem;
  border: 1px solid #e3e3e3;
  box-shadow: 0 0.9px 2.2px rgba(0, 0, 0, 0.02),
    0 2.2px 5.3px rgba(0, 0, 0, 0.028), 0 4.1px 10px rgba(0, 0, 0, 0.035),
    0 7.4px 17.9px rgba(0, 0, 0, 0.042), 0 13.8px 33.4px rgba(0, 0, 0, 0.05),
    0 33px 80px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
  padding: 2px;
  border-radius: 1rem;
  color: #333;
  margin: 4rem 0;
  :hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
  }
  text-align: center;
  margin-top: 4rem;

  h1 {
    margin: 3rem 0;
    font-size: 2rem;
  }
  h2 {
    margin: 3rem 0;
    font-size: 2rem;
  }
`
export default Tarifs
