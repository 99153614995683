import React, { useContext, useState } from "react"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"
import { GlobalContext } from "../context/GlobalContext"

const Apropos = () => {
  const data = useContext(GlobalContext)
  const [about] = useState(data[0].allDatoCmsAbout.edges[0].node)
  return (
    <>
      <h2
        style={{
          textAlign: "center",
          color: "#333",
          marginTop: "2rem",
          fontSize: "2rem",
        }}
      >
        QU’EST CE QUE LA MÉTHODE PILATES ?
      </h2>
      <AboutWrapper>
        <img src={about.picture.url} alt="Logo Choréart Pilates" />
        <h1>
          <ReactMarkdown source={about.description} />
        </h1>
      </AboutWrapper>
    </>
  )
}

const AboutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
  color: #333;
  width: 90%;
  justify-content: center;

  img {
    width: 35rem;
    height: auto;
    box-shadow: 0 0.9px 2.2px rgba(0, 0, 0, 0.02),
      0 2.2px 5.3px rgba(0, 0, 0, 0.028), 0 4.1px 10px rgba(0, 0, 0, 0.035),
      0 7.4px 17.9px rgba(0, 0, 0, 0.042), 0 13.8px 33.4px rgba(0, 0, 0, 0.05),
      0 33px 80px rgba(0, 0, 0, 0.07);
    border-radius: 50px;
  }
  h1 {
    width: 50%;
    margin: 5rem 0;
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 2rem;
    text-align: justify;
  }

  @media screen and (max-width: 375px) {
    h1 {
      width: 100%;
    }
    img {
      width: 20rem;
    }
  }
`

export default Apropos
